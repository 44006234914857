import { Link } from "react-router-dom";

function Home() {

    const scrollTo = (id: string) => {
        const element = document.getElementById(id)
        if (element) {
            const offset = element.offsetTop - 150; // Header de 150px
            window.scrollTo({
                top: offset,
                behavior: "smooth"
            });
        }
    }

    return (
        <div id="Header" className="header">
            <img
                alt="Logo"
                src="/img/FCVINVERSIONES.png"
            />
            <ul>
                <li><Link to="" onClick={() => scrollTo("Carousel")}>Inicio</Link></li>
                <li><Link to="" onClick={() => scrollTo("Advertise")}>¿Quiénes Somos?</Link></li>
                <li><Link to="" onClick={() => scrollTo("Features")}>Líneas de Negocio</Link></li>
                <li><Link to="" onClick={() => scrollTo("Footer")}>Contacto</Link></li>
            </ul>
        </div>
    );
}

export default Home;