import { faAward, faBalanceScale, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Advertise() {
    return (
        <div id={"Advertise"} className="advertise">
            <img
                alt="Img"
                src="/img/FCV7.jpg"
            />
            <div className="advertise-mision">
                <div className="advertise-subsection">
                    <FontAwesomeIcon icon={faAward} color='#0F757D' size='2x' />
                    <h1>MISIÓN</h1>
                </div>
                <p>Crear puentes de comercialización entre compañías y
                    personas que generen valor a todos los involucrados, en
                    cualquier sector económico o industria, que incluya
                    tecnologías digitales disruptivas, para el crecimiento orgánico
                    de personas, empresas e inversionistas.</p>
            </div>
            <div className="advertise-vision">
                <div className="advertise-subsection">
                    <FontAwesomeIcon icon={faEye} color='#0F757D' size='2x' />
                    <h1>VISIÓN</h1>
                </div>
                <p>Estar entre las tres primeras empresas comercializadoras del
                    mercado de negocios digitales disruptivos para el año 2030.</p>
            </div>
            <div className="advertise-valores">
                <div className="advertise-subsection">
                    <FontAwesomeIcon icon={faBalanceScale} color='#0F757D' size='2x' />
                    <h1>VALORES DE LA ORGANIZACIÓN</h1>
                </div>
                <ul>
                    <li>Facilidad para hacer negocios en donde se entrega lo que necesita cada involucrado en él.</li>
                    <li>Convicción de ofrecer lo mejor para cada cliente.</li>
                    <li>Verdad en cada interrelación que desarrolle la empresa con un enfoque en la transparencia y sinceridad hacia el cliente.</li>
                    <li>Fe en las actividades que se deban desarrollar para lograr un objetivo común de crecimiento individual y colectivo.</li>
                    <li>Compromiso en mantener las relaciones entre las personas y empresas.</li>
                    <li>Veracidad en la información que se comparte.</li>
                    <li>Respeto, paciencia y tolerancia en las actividades comerciales, administrativas y financieras que se desarrollen en la organización.</li>
                </ul>
            </div>
        </div>
    );
}

export default Advertise;