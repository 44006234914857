import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Portfolio() {
  return (
    <div id={"Portfolio"} className="portfolio">
      <h1>Aliados de Negocio</h1>
      <ul>
      <li><a href="https://www.dell.com/es-co/lp" target="_blank" rel="noreferrer"><img style={{ padding: 10 }} src="/img/Dell.png" alt="Dell" /></a></li>
        <li><a href="https://www.microsoft.com/" target="_blank" rel="noreferrer"><img style={{ padding: 10 }} src="/img/Microsoft.png" alt="Microsoft" /></a></li>
        <li><a href="https://www.arubanetworks.com/es/" target="_blank" rel="noreferrer"><img style={{ padding: 10 }} src="/img/HPE.png" alt="HPE Aruba" /></a></li>
        {/* <li style={{ backgroundColor: '#F8D849' }}><a href="https://www.mps.com.co/" target="_blank" rel="noreferrer"><img src="/img/MPS.png" alt="MPS Mayorista" /></a></li> */}
        <li><a href="https://www.tmconsulting.com.co/" target="_blank" rel="noreferrer"><img style={{ padding: 10 }} src="/img/TM.png" alt="TM Consulting" /></a></li>
      </ul>

      <h2>Síguenos en:</h2>
      <div className="portfolio-icons">
        {/* <FontAwesomeIcon
          onClick={() => window.open('https://www.facebook.com/FCV-Inversiones-106993967778852/', '_blank')}
          icon={faFacebook} size="2x" color="#FFFFFF" /> */}
        <FontAwesomeIcon
          onClick={() => window.open('https://twitter.com/fcvinversiones', '_blank')}
          icon={faTwitter} size="2x" color="#FFFFFF" />
        <FontAwesomeIcon
          onClick={() => window.open('https://www.instagram.com/fcvinversiones/', '_blank')}
          icon={faInstagram} size="2x" color="#FFFFFF" />
        <FontAwesomeIcon
          onClick={() => window.open('https://www.linkedin.com/in/fcv-inversiones-88b9091b3/', '_blank')}
          icon={faLinkedin} size="2x" color="#FFFFFF" />
        <FontAwesomeIcon
          onClick={() => window.open('https://api.whatsapp.com/send?phone=+573102325687&text=Hola,%20quiero%20más%20info', '_blank')}
          icon={faWhatsapp} size="2x" color="#FFFFFF" />
      </div>
    </div>
  );
}

export default Portfolio;