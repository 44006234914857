function MiniSection() {

  const scrollDown = () => {
    const element = document.getElementById("Footer")
    if (element) {
        const offset = element.offsetTop - 150; // Header de 150px
        window.scrollTo({
            top: offset,
            behavior: "smooth"
        });
    }
}

  return (
    <div id="MiniSection" className="minisection">
      <p><b>FCV INVERSIONES</b> es una empresa en el sector de las TIC
        comercializadora de productos y servicios, con enfoque en tecnología digital 
        disruptiva, que se encarga de crear puentes de negociación entre compañías y/o personas.
      </p>
      <button onClick={scrollDown}>PARA MÁS INFORMACIÓN CONTÁCTENOS</button>
    </div>
  );
}

export default MiniSection;