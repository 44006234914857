function Footer() {
  return (
    <div id={"Footer"} className="footer">
      <p>© Todos los derechos reservados para www.fcvinversiones.com</p>
      <p>Utilizamos cookies para asegurar que damos la mejor experiencia al usuario en nuestra web. Si sigues utilizando este sitio asumiremos que estás de acuerdo.</p>
      <p>@FCV 2024</p>
    </div>
  );
}

export default Footer;