import { faFacebook, faTwitter, faInstagram, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLocationDot, faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function Contact() {
    const [formData, setFormData] = useState<{ nombre: string, email: string, mensaje: string }>({
        nombre: '',
        email: '',
        mensaje: '',
    });

    const [formErrors, setFormErrors] = useState<{ nombre: string, email: string, mensaje: string }>({
        nombre: '',
        email: '',
        mensaje: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
        setFormErrors({
            ...formErrors,
            [id]: '',
        })
    };

    const validateForm = () => {
        let valid = true;
        const errors = {
            nombre: '',
            email: '',
            mensaje: '',
        };

        if (formData.nombre.trim() === '') {
            errors.nombre = 'El nombre es requerido';
            valid = false;
        }

        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
            console.log('MAL')
            errors.email = 'Ingresa un email válido';
            valid = false;
        }

        if (formData.mensaje.trim() === '') {
            errors.mensaje = 'El mensaje es requerido';
            valid = false;
        }

        setFormErrors(errors);
        return valid;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            const { nombre, email, mensaje } = formData;

            const body = `Nombre: ${nombre}%0AEmail: ${email}%0AMensaje: ${mensaje}`;
            const mailtoLink = `mailto:contacto@fcvinversiones.com?subject=Contacto desde fcvinversiones.com&body=${body}`;

            window.location.href = mailtoLink;
        }
    };

    return (
        <div id={"Contact"} className="contact">
            <div className="contact-left">
                <p>
                    Para nosotros es muy importante su
                    mensaje y por eso ponemos a su disposición
                    nuestro formulario de contacto. Por favor,
                    ingrese los datos solicitados y lo
                    contactaremos en el menor tiempo posible.
                    ¡Muchas gracias!
                </p>
                <div className="contact-info">
                    <FontAwesomeIcon icon={faEnvelope} size="1x" color="#000000" />
                    <h2>contacto@fcvinversiones.com</h2>
                </div>
                <div className="contact-info">
                    <FontAwesomeIcon icon={faLocationDot} size="1x" color="#000000" />
                    <h2>Carrera 7B # 135 – 77 Of. 202, Bogotá, Colombia</h2>
                </div>
                <div className="contact-info">
                    <FontAwesomeIcon icon={faSquarePhone} size="1x" color="#000000" />
                    <h2>Teléfono Fijo: +57 601 5308578</h2>
                </div>
                <div className="contact-icons">
                    {/* <FontAwesomeIcon
                        onClick={() => window.open('https://www.facebook.com/FCV-Inversiones-106993967778852/', '_blank')}
                        icon={faFacebook} size="1x" color="#FFFFFF" /> */}
                    <FontAwesomeIcon
                        onClick={() => window.open('https://twitter.com/fcvinversiones', '_blank')}
                        icon={faTwitter} size="1x" color="#FFFFFF" />
                    <FontAwesomeIcon
                        onClick={() => window.open('https://www.instagram.com/fcvinversiones/', '_blank')}
                        icon={faInstagram} size="1x" color="#FFFFFF" />
                    <FontAwesomeIcon
                        onClick={() => window.open('https://www.linkedin.com/in/fcv-inversiones-88b9091b3/', '_blank')}
                        icon={faLinkedin} size="1x" color="#FFFFFF" />
                    <FontAwesomeIcon
                        onClick={() => window.open('https://api.whatsapp.com/send?phone=+573102325687&text=Hola,%20quiero%20más%20info', '_blank')}
                        icon={faWhatsapp} size="1x" color="#FFFFFF" />
                </div>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="nombre">Nombre</label>
                    <input
                        type="text"
                        className="form-control"
                        id="nombre"
                        placeholder="Nombre"
                        value={formData.nombre}
                        onChange={handleInputChange}
                    />
                    {formErrors.nombre && <span className="contact-errormsj">{formErrors.nombre}</span>}
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    {formErrors.email && <span className="contact-errormsj">{formErrors.email}</span>}
                </div>
                <div>
                    <label htmlFor="mensaje">Mensaje</label>
                    <textarea
                        className="form-control"
                        id="mensaje"
                        placeholder="Mensaje"
                        value={formData.mensaje}
                        onChange={handleInputChange}
                    ></textarea>
                    {formErrors.mensaje && <span className="contact-errormsj">{formErrors.mensaje}</span>}
                </div>
                <div>
                    <input type="submit" value="ENVIAR MENSAJE" className="form-control contact-submit" />
                </div>
            </form>
        </div>
    );
}

export default Contact;