import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as Sliders } from 'react-responsive-carousel';

const Carousel: React.FC = () => {

    return (
        <div id="Carousel" className="carousel">
            <Sliders showThumbs={false} showArrows={true} showIndicators={false} transitionTime={4} autoPlay={true}>
                <div className='carousel-img'>
                    <img
                        alt={"Img1"}
                        src="/img/FCV2.jpg"
                    />
                    <div className="carousel-text">
                        <p>Facilidad</p>
                    </div>
                </div>
                <div className='carousel-img'>
                    <img alt={"Img2"}
                        src="/img/FCV3.jpg"
                    />
                    <div className="carousel-text">
                        <p>Crecimiento</p>
                    </div>
                </div>
                <div className='carousel-img'>
                    <img alt={"Img3"}
                        src="/img/FCV4.jpg"
                    />
                    <div className="carousel-text">
                        <p>Valores</p>
                    </div>
                </div>
            </Sliders>
        </div>

    );
}

export default Carousel;