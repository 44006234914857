import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Whatsapp() {

    return (
        <div id="Whatsapp" className="whatsapp">
            <FontAwesomeIcon
                onClick={() => window.open('https://api.whatsapp.com/send?phone=+573102325687&text=Hola,%20quiero%20más%20info', '_blank')}
                icon={faWhatsapp} size="2x" color="#FFFFFF" />
        </div>
    );
}

export default Whatsapp;