import Advertise from '../components/Advertise';
import Carousel from '../components/Carousel';
import Contact from '../components/Contact';
import Features from '../components/Features';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Map from '../components/Map';
import MiniSection from '../components/MiniSection';
import Portfolio from '../components/Portfolio';
import Whatsapp from '../components/Whatsapp';

function Home() {
  return (
    <>
      <Header />
      <div className='base'>
        <Carousel />
        <MiniSection />
        <Advertise />
        <Features />
        <Portfolio />
        { /* <Map /> */ }
        <Contact />
        <Footer />
      </div>
      <Whatsapp />
    </>
  );
}

export default Home;