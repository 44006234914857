import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Features() {
    return (
        <div id={"Features"} className="features">
            <h1>LÍNEAS DE NEGOCIO</h1>
            <div className="features-pos1 features-separator" />
            <FontAwesomeIcon icon={faBriefcase} size="2x" color="#000000" className="features-pos2" />
            <div className="features-pos3 features-separator" />
            <img
                className="features-img-pos1"
                alt="FCVTecnologia"
                src="/img/FCVTECNOLOGIA.png"
            />
            <img
                className="features-img-pos2"
                alt="FCVInnovacion"
                src="/img/FCVINNOVACION.png"
            />
            <img
                className="features-img-pos3"
                alt="FCVServicio"
                src="/img/FCVSERVICIO.png"
            />
            <h2 className="features-text-pos1">
                Línea de negocio orientada a ofrecer servicios y
                soluciones que permitan el uso de las tecnologías de
                información y comunicaciones en la pequeña y
                mediana empresa. Se destacan dos frentes principales:
            </h2>
            <h2 className="features-text-pos2">
                Línea de negocio orientada a comercializar y ofrecer
                en el mercado tecnologías disruptivas para la realización
                de negocios con apoyo principalmente a ideas de emprendimientos.
                Se ofrecen dos enfoques:
            </h2>
            <h2 className="features-text-pos3">
                Esta línea de negocio se orienta en la atención de las
                necesidades intangibles de los clientes para entender,
                implementar o soportar actividades del negocio, con un
                enfoque social. Se destacan los siguientes servicios:
            </h2>
            <ul className="features-list-pos1">
                <li>
                    <b>Ciberseguridad.</b> Ante la necesidad
                    creciente de las organizaciones y personas
                    de tener información en el momento en que
                    se necesita de una manera segura, se hace
                    imperioso la protección de la información
                    como uno de los retos más importantes en el
                    mundo actual, en donde, tanto la tecnología
                    en sitio como en nube, tengan procesos y
                    controles adecuados para tal fin.
                </li>
                <li>
                    <b>INFRAESTRUCTURA.</b> Para el enfoque de
                    empresa de esta línea, se busca proveer la
                    infraestructura de tecnología de información
                    y comunicaciones que pueda ser requerida por
                    las organizaciones en tiempo, calidades
                    y precio justo.
                </li>
            </ul>
            <ul className="features-list-pos2">
                <li>
                    <b>Comercializacion de Tecnologías Disruptivas.</b> Con
                    el crecimiento de nuevas formas de comunicarse
                    entre las personas, como los son las apps o redes
                    sociales, tecnologías como blockchain, internet de
                    las cosas, machine learning, big data o inteligencia
                    artificial, permiten la creación de nuevas formas de
                    hacer negocios y crear valor para las empresas.
                </li>
                <li>
                    <b>Estructuración y Apoyo a Startups.</b> Una buena
                    idea, siempre debe estar acompañada de una correcta
                    asesoría en su estructuración. Este servicio se
                    orienta en brindar apoyo comercial, consecución
                    de recursos, y estructuración de procesos para que
                    la idea se convierta en una empresa con proyección
                    de crecimiento e impacto social.
                </li>
            </ul>
            <ul className="features-list-pos3">
                <li>
                    <b>Servicios de Implementación Tecnológica.</b> Servicios
                    que permiten la implementación y
                    soporte a las plataformas tecnológicas
                    ofrecidas a los clientes.
                </li>
                <li>
                    <b>Servicios de Consultoría.</b> Servicios que
                    apoyan los procesos de seguridad y los procesos
                    de negocio de las organizaciones a través de
                    consultores especializados en el área de aplicación.
                </li>
                <li>
                    <b>Servicios de Comercialización de Productos.</b> Servicio
                    que se orienta en apoyar a las compañías
                    en la comercialización de sus productos adelantando
                    procesos comerciales y de estrategia en el mercado
                    de aplicación del cliente.
                </li>
                <li>
                    <b>Servicio Social.</b> Tiempo y esfuerzo facilitado
                    a fundaciones o instituciones que trabajen con
                    población vulnerable, aportando talento humano o
                    recursos económicos que tenga capacidad FCV INVERSIONES
                    de conseguir dentro o fuera de la organización.
                </li>
            </ul>
        </div>
    );
}

export default Features;